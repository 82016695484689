@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(15rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottomBig {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes wait {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: .1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scroll-down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(1rem);
  }

  100% {
    transform: translateY(0);
  }
}

.translate-left {
  opacity: 0;
  transform: translateX(-10rem);
}

.translate-right {
  opacity: 0;
  transform: translateX(10rem);
}

.translate-down {
  opacity: 0;
  transform: translateY(10rem);
}

.hidden {
  opacity: 0;
}

.hidden--modal {
  visibility: hidden;
  opacity: 0;
}

.visible--modal {
  visibility: visible;
  opacity: 1;
}

.hidden__display--none {
  display: none;
}

.lazy-img {
  filter: blur(20px);
}

.mb-sm {
  margin-bottom: 1.5rem;
}

.mb-md {
  margin-bottom: 3rem;
}

.mb-lg {
  margin-bottom: 4rem;
}

.mb-hg {
  margin-bottom: 6.5rem;
}

.mt-sm {
  margin-top: 1.5rem;
}

.mt-md {
  margin-top: 3rem;
}

.mt-lg {
  margin-top: 4rem;
}

.mt-hg {
  margin-top: 6.5rem;
}

.ml-sm {
  margin-left: 1.5rem;
}

.ml-md {
  margin-left: 3rem;
}

.ml-lg {
  margin-left: 4rem;
}

.ml-hg {
  margin-left: 6.5rem;
}

.hover-translate {
  transition: all .2s;
}

.hover-translate:hover {
  transform: translateY(-.2rem);
  box-shadow: 0 1rem 2rem #0003;
}

.hover-translate:active {
  transform: scale(.99)translateY(.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-family: futura-pt, sans-serif;
  font-size: 62.5%;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}

@media only screen and (max-width: 37.5em) {
  html {
    font-size: 43.75%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

@media only screen and (max-height: 53.25em) {
  html {
    font-size: 50%;
  }
}

::selection {
  background-color: #aaa;
}

video::-webkit-media-controls {
  display: none !important;
}

.heading-4, .heading-3, .heading-2, .heading-1 {
  font-size: "futura-pt", sans-serif;
  font-weight: 400;
}

.heading-1 {
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: amandine, sans-serif;
  font-size: 6rem;
  line-height: 1;
}

@media only screen and (max-width: 37.5em) {
  .heading-1 {
    font-size: 5rem;
  }
}

.heading-2 {
  font-size: 4rem;
  line-height: 1;
}

.heading-3 {
  text-transform: uppercase;
  font-size: 2rem;
}

.heading-4 {
  font-size: 1.9rem;
}

.text-bold {
  font-weight: 500;
}

.to-do {
  color: red;
  background-color: #ff0;
  font-weight: 500;
}

.link-basic {
  color: #876d33;
  font-weight: 500;
  text-decoration: none;
}

.link-basic:hover {
  color: #bf9f58;
  cursor: pointer;
}

.arrow {
  border: 2px solid;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transition: all .2s ease-out;
  display: inline-block;
  transform: rotate(-45deg);
}

.arrow__up {
  transform: rotate(-135deg);
}

.arrow__left {
  transform: rotate(135deg);
}

.btn, .btn:link, .btn:visited {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #bf9f58;
  border: none;
  border-radius: 1rem;
  align-items: center;
  padding: 1.8rem 3rem;
  font-family: futura-pt, sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.btn:hover {
  background-color: #876d33;
  transform: translateY(-.4rem);
  box-shadow: 0 1rem 2rem #0003;
}

.btn:active {
  transform: scale(.99)translateY(.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

@media only screen and (max-width: 37.5em) {
  .btn {
    font-size: 2rem;
  }
}

.btn__simple, .btn__simple:link, .btn__simple:visited {
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-bottom: 2px solid #0000;
  border-radius: 0;
  align-items: center;
  padding: .5rem 2rem;
  font-family: futura-pt, sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.btn__simple:hover {
  color: #876d33;
  border-bottom: 2px solid #fff;
  transform: translateY(-.2rem);
}

.btn__simple:active {
  transform: translateY(.07rem);
}

.card__container {
  perspective: 150rem;
  width: 35rem;
  height: 28rem;
}

.card__side {
  -webkit-backdrop-filter: blur(7px);
  perspective: 150rem;
  backface-visibility: hidden;
  background: #fff6;
  border: 1px solid #fff6;
  border-radius: 16px;
  justify-self: center;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 30px #0000001a;
}

.card__main {
  color: #404040;
  opacity: 1;
  padding: 2rem;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
}

.card__main--hidden {
  visibility: hidden;
  opacity: 0;
  transform: rotateY(-180deg);
}

.card__main--heading {
  width: 80%;
  height: 5rem;
  margin-bottom: 1rem;
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 1.2;
}

.card__main--subtitle {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 300;
}

.card__main--btn-wrapper {
  justify-content: space-around;
  display: flex;
}

.card__main--facts {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
}

.card__main--factline {
  color: #404040;
}

.card__main--factline:not(:last-child) {
  border-right: 1px solid #404040;
}

.card__main--factline-value {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 500;
  display: block;
}

.card__main--factline-description {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
}

.card__main--factline-icon {
  height: 3rem;
}

.card__main--factline-text {
  font-size: 1.5rem;
}

.card__main--banner {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  background-color: #dfcfab;
  border: 1px solid #dfcfab;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: 5%;
  right: 10%;
  transform: translateX(50%)translateY(50%)rotateZ(45deg);
}

.card__main--btn {
  justify-content: center;
  width: 13rem;
  height: 2rem;
  margin: 1rem 0 4rem;
  font-weight: 500;
}

.card__main--btn, .card__main--btn:link, .card__main--btn:visited {
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 20rem;
  align-items: center;
  padding: 1.8rem 3rem;
  font-size: 1.5rem;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.card__main--btn:hover {
  transform: translateY(-.1rem);
  box-shadow: 0 1rem 2rem #0003;
}

.card__main--btn:active {
  transform: scale(.99)translateY(.1rem);
  box-shadow: 0 .5rem 1rem #0003;
}

.card__main--btn-details {
  color: #bf9f58;
  background-color: #0000;
  border: 2px solid #bf9f58;
}

.card__main--btn-details:hover {
  color: #fff;
  background-color: #bf9f58;
}

.card__main--btn-booking {
  color: #fff;
  background-color: #bf9f58;
  border: 2px solid #bf9f58;
}

.card__main--btn-booking:hover {
  color: #bf9f58;
  background-color: #0000;
}

.card__back {
  color: #404040;
  visibility: hidden;
  opacity: 0;
  padding: 2rem;
  transition: all .5s;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotateY(180deg);
}

.card__back--active {
  visibility: visible;
  opacity: 1;
  transform: rotateY(0);
}

.card__back--close {
  color: #0000;
  opacity: .8;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.card__back--close:before, .card__back--close:after {
  content: " ";
  background-color: #404040;
  width: 3px;
  height: 2rem;
  position: absolute;
  left: 1rem;
}

.card__back--close:before {
  transform: rotate(45deg);
}

.card__back--close:after {
  transform: rotate(-45deg);
}

.card__back--close:hover {
  opacity: 1;
}

.card__btn--accordion {
  width: 100%;
  transition: all .5s;
}

.card__btn--accordion, .card__btn--accordion:link, .card__btn--accordion:visited {
  color: #202020;
  cursor: pointer;
  background-color: #f9f7f6;
  border: none;
  align-items: center;
  padding: .5rem 3rem;
  font-family: futura-pt, sans-serif;
  font-size: 1.5rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .2s;
  display: flex;
}

.card__btn--arrow {
  color: currentColor;
}

.card__accordion {
  max-height: 0;
  transition: max-height .25s ease-out;
  overflow: hidden;
}

.card__details {
  color: #202020;
  text-align: justify;
  -webkit-hyphens: auto;
  hyphens: auto;
  padding: 2rem 0 1rem;
  font-size: 1.5rem;
  line-height: 1.4;
}

.card__warning {
  -webkit-hyphens: auto;
  hyphens: auto;
  align-items: center;
  padding: 0;
  font-size: 1.2rem;
  display: flex;
}

.card__warning--icon {
  height: 3rem;
  margin-right: 1.5rem;
}

.card__warning--text {
  text-align: justify;
}

.carousel-indicator {
  color: #dfcfab;
  cursor: pointer;
  padding: 0 .4rem;
  font-size: 2.5rem;
}

.carousel-indicator:hover {
  color: #bf9f58;
}

.carousel-indicator--active {
  color: #876d33;
}

hr {
  border: 0;
  border-bottom: 1px solid #bf9f584d;
  width: 100%;
  height: 0;
  margin: 0;
  display: block;
}

.modal {
  z-index: 1000;
  background-color: #fff;
  background-image: radial-gradient(at top, #dfcfab4d, #dfcfab4d);
  border-radius: 1rem;
  max-width: 120rem;
  padding: 5rem 6rem;
  transition: all .3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #0000004d;
}

@media only screen and (max-width: 37.5em) {
  .modal {
    width: 80vw;
  }
}

@media only screen and (max-width: 56.25em) {
  .modal {
    padding: 4rem 5rem;
  }
}

.modal__heading-underline {
  opacity: .25;
  z-index: -10;
  width: 35rem;
  height: 6rem;
  position: absolute;
  top: 5rem;
  left: 2rem;
}

.overlay {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 100;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.modal__header {
  margin-bottom: 4.5rem;
  font-size: 3.25rem;
  line-height: 1.5;
}

.modal__form {
  margin: 0;
}

@media only screen and (max-width: 56.25em) {
  .modal__form {
    margin: 0;
  }
}

.modal__form--grid {
  -moz-column-gap: 3rem;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 3rem;
  display: grid;
}

@media only screen and (max-width: 37.5em) {
  .modal__form--grid {
    display: block;
  }
}

.modal__form--group.anfrage {
  grid-area: 1 / 2 / -1 / -1;
  align-self: stretch;
}

.modal__form--input {
  color: inherit;
  background-color: #ffffff80;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: .5rem;
  width: 90%;
  padding: .8rem 2rem;
  font-family: inherit;
  font-size: 1.25rem;
  transition: all .3s;
  display: block;
}

@media only screen and (max-width: 37.5em) {
  .modal__form--input {
    width: 100%;
  }
}

.modal__form--input:focus {
  border-bottom: 3px solid #618877;
  outline: none;
  box-shadow: 0 1rem 2rem #0000001a;
}

.modal__form--input:focus:invalid {
  border-bottom: 3px solid #72322a;
}

.modal__form--input::-webkit-input-placeholder {
  color: #aaa;
}

.modal__form--label {
  height: 1.7rem;
  margin-bottom: 3rem;
  margin-left: 2rem;
  font-size: 1.1rem;
  font-weight: 300;
  transition: all .3s;
  display: block;
}

.modal__form--input:placeholder-shown + .modal__form--label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-3.5rem);
}

.modal__form--btn {
  justify-content: center;
  width: 17rem;
  height: 4.5rem;
  margin: 0 auto;
}

.modal__form textarea {
  resize: none;
  width: 50rem;
  height: calc(100% - 4.7rem);
  padding: 1rem 1.5rem;
  font-family: futura-pt, sans-serif;
  font-size: 1.25rem;
}

@media only screen and (max-width: 56.25em) {
  .modal__form textarea {
    width: 37rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .modal__form textarea {
    width: 100%;
    height: 20vh;
  }
}

.modal__form textarea:focus::placeholder {
  color: #0000;
}

.modal__form button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 1rem;
}

.btn--close-modal {
  color: #bf9f58;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  transition: all .2s;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.btn--close-modal:hover {
  color: #876d33;
}

.modal-banner {
  z-index: 1000;
  background-color: #fff;
  background-image: radial-gradient(at top, #dfcfab4d, #dfcfab4d);
  border-radius: 1rem;
  grid-template-columns: repeat(2, 1fr);
  width: 80rem;
  height: 50rem;
  transition: all .5s;
  display: grid;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #0000004d;
}

@media only screen and (max-width: 56.25em) {
  .modal-banner {
    width: 70rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .modal-banner {
    width: 80vw;
    height: 80vh;
    display: block;
  }
}

.modal-banner__btn--close-modal {
  color: #bf9f58;
  cursor: pointer;
  z-index: 2000;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 4rem;
  transition: all .2s;
  position: absolute;
  top: .5rem;
  right: 2rem;
}

.modal-banner__btn--close-modal:hover {
  color: #876d33;
}

@media only screen and (max-width: 37.5em) {
  .modal-banner__btn--close-modal {
    text-align: center;
    background-color: #ffffffb3;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    top: 2rem;
  }
}

.modal-banner__textarea {
  grid-column: 2;
  padding: 6rem 5rem;
}

@media only screen and (max-width: 56.25em) {
  .modal-banner__textarea {
    padding: 4rem 3rem;
  }
}

.modal-banner__heading {
  color: #bf9f58;
  font-family: liana, cursive;
  font-size: 6rem;
  line-height: 1;
}

.modal-banner__text {
  font-size: 1.4rem;
}

.modal-banner__signature {
  color: #bf9f58;
  font-family: liana, cursive;
  font-size: 3rem;
  line-height: 1;
}

.modal-banner__picture {
  height: 100%;
  transform: translateX(-15rem);
}

@media only screen and (max-width: 37.5em) {
  .modal-banner__picture {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    transform: none;
  }
}

.modal-banner__picture--container {
  -webkit-clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 70% 100%, 0 100%);
  grid-column: 1;
  overflow: hidden;
}

@media only screen and (max-width: 37.5em) {
  .modal-banner__picture--container {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 100%);
    height: 30vh;
  }
}

.lds-roller__overlay {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  z-index: 10000;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  transition: all .3s;
  position: fixed;
  top: 0;
  left: 0;
}

.lds-roller {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller__checkout {
  z-index: 20000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  transform-origin: 40px 40px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-roller;
}

.lds-roller div:after {
  content: " ";
  background: #bf9f58;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  display: block;
  position: absolute;
}

.lds-roller div:first-child {
  animation-delay: -.036s;
}

.lds-roller div:first-child:after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.footer {
  background-color: #404040;
  grid-column: full-start / full-end;
  padding: 5rem;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .footer {
    padding: 4rem 5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .footer {
    height: 30rem;
  }
}

@media only screen and (min-width: 112.5em) {
  .footer {
    padding: 5rem calc(50vw + -900px + 5rem);
  }
}

.footer__name-container {
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
  display: flex;
}

.footer__name-container--name {
  color: #fff;
  font-family: liana, cursive;
  font-size: 5rem;
}

.footer__name-container--und {
  color: #fff;
  padding: 0 .5rem;
  font-family: amandine, sans-serif;
  font-size: 1.5rem;
}

.footer__copyright {
  color: #fff;
  border-top: .5px solid #fff;
  justify-content: space-between;
  padding-top: 1rem;
  display: flex;
}

@media only screen and (max-width: 25em) {
  .footer__copyright {
    text-align: center;
    display: block;
  }
}

.header {
  grid-template-columns: 1fr;
  align-items: center;
  height: 100dvh;
  padding: 0 0 0 10rem;
  transition: height 999999s;
  display: grid;
  overflow: hidden;
}

@media only screen and (min-width: 112.5em) {
  .header {
    padding: 5rem calc(50vw + -900px + 5rem);
  }
}

@media only screen and (max-width: 37.5em) {
  .header {
    padding: 0;
  }
}

@media only screen and (max-height: 37.5em) {
  .header {
    padding: 0 5rem;
  }
}

.header__bg-video {
  filter: grayscale();
  z-index: -100;
  min-width: 100vw;
  min-height: 100dvh;
  margin: auto;
  position: absolute;
  inset: -9999px;
}

.header__date-container {
  transition: all 1s;
}

@media only screen and (max-width: 37.5em) {
  .header__date-container {
    display: inline-flex;
    position: absolute;
    top: 7dvh;
    left: 50%;
    transform: translate(-50%);
  }
}

.header__date-container--date {
  color: #fff;
  margin-bottom: -3rem;
  font-family: amandine, sans-serif;
  font-size: 12rem;
}

@media only screen and (max-width: 37.5em) {
  .header__date-container--date {
    margin: 0 5vw;
    font-size: 10rem;
  }
}

@media only screen and (max-height: 37.5em) {
  .header__date-container--date {
    font-size: 10rem;
  }
}

@media only screen and (max-width: 25em) {
  .header__date-container--date {
    margin: 0 5vw;
    font-size: 7.5rem;
  }
}

.header__date-container--hidden {
  opacity: 0;
  transform: translateX(-20rem);
}

.header__name-container {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

@media only screen and (max-width: 37.5em) {
  .header__name-container {
    flex-direction: column;
    bottom: 10dvh;
  }
}

@media only screen and (max-height: 37.5em) {
  .header__name-container {
    flex-direction: column;
    bottom: 10dvh;
  }
}

.header__name-container--name {
  color: #fff;
  font-family: liana, cursive;
  font-size: 10rem;
}

@media only screen and (max-width: 25em) {
  .header__name-container--name {
    font-size: 8.5rem;
  }
}

.header__name-container--und {
  color: #fff;
  margin: -2.5rem 0;
  padding: 0 1.5rem;
  font-family: amandine, sans-serif;
  font-size: 3rem;
}

@media only screen and (max-width: 25em) {
  .header__name-container--und {
    font-size: 2.5rem;
  }
}

.header__btn {
  opacity: 0;
  justify-content: center;
  width: 60%;
  animation-name: wait, moveInBottom;
  animation-duration: 2s, 1s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1, 1;
  animation-direction: normal, normal;
  animation-play-state: running, running;
  animation-delay: 0s, 1s;
  animation-fill-mode: forwards;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .header__btn {
    width: 80%;
    font-size: 1.8rem !important;
  }
}

@media only screen and (max-width: 25em) {
  .header__btn {
    width: 90%;
    font-size: 1.8rem !important;
  }
}

.header__scroll-down {
  position: absolute;
  bottom: 2rem;
  left: 50%;
}

@media only screen and (max-width: 56.25em) {
  .header__scroll-down {
    display: none;
  }
}

.header__scroll-down--icon {
  height: 3vw;
  min-height: 10rem;
  animation: 2s infinite scroll-down;
}

.maps {
  grid-column: 2 / -1;
  height: 50rem;
}

#map {
  width: 100%;
  height: 100%;
}

.navbar {
  z-index: 100;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  height: 7rem;
  padding: 0 3vw;
  display: grid;
  position: fixed;
  top: 0;
}

@media only screen and (min-width: 112.5em) {
  .navbar {
    padding: 0 calc(50vw + -900px + 5rem);
  }
}

@media only screen and (max-width: 75em) {
  .navbar {
    justify-items: end;
    padding: 0;
  }
}

.navbar__btn--login {
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 0 0 4px #000;
}

.navbar__btn--login:hover {
  color: #fff;
  background-color: #000;
  box-shadow: inset 0 0 #000;
}

@media only screen and (max-width: 56.25em) {
  .navbar__btn--login {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .navbar__btn--login {
    display: none;
  }
}

.navbar__btn--section {
  color: #fff !important;
  font-size: 2rem !important;
}

@media only screen and (max-width: 75em) {
  .navbar__btn--section {
    justify-content: flex-end;
    width: auto;
    padding: .5rem 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .navbar__btn--section {
    font-size: 2rem;
  }
}

.navbar__btn--section--mobile {
  flex-direction: column;
  align-items: flex-start;
  color: #000 !important;
}

.navbar__section-buttons {
  grid-column-gap: 0rem;
  grid-template-columns: 1fr minmax(max-content, 1fr) repeat(4, 1fr);
  place-items: center;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .navbar__section-buttons {
    display: none;
  }
}

.navbar__button--open {
  text-align: center;
  cursor: pointer;
  background-color: #0000;
  width: 3rem;
  height: 3rem;
  display: block;
}

.navbar__icon {
  color: #000;
  margin-top: 50%;
  position: relative;
}

.navbar__icon, .navbar__icon:before, .navbar__icon:after {
  background-color: #000;
  width: 3rem;
  height: 2px;
  display: inline-block;
}

.navbar__icon:before, .navbar__icon:after {
  content: "";
  transition: all .2s;
  position: absolute;
  left: 0;
}

.navbar__icon:before {
  top: -.8rem;
}

.navbar__icon:after {
  top: .8rem;
}

.navbar__checkbox {
  width: 4rem;
  height: 4rem;
  display: none;
}

.navbar__nav-dropdown {
  margin-left: 3rem;
  padding-left: 10rem;
  padding-right: 4vw;
  display: none;
  position: relative;
}

@media only screen and (max-width: 75em) {
  .navbar__nav-dropdown {
    display: inline-block;
  }
}

@media only screen and (max-width: 37.5em) {
  .navbar__nav-dropdown {
    justify-self: end;
  }
}

.navbar__nav-dropdown--content {
  visibility: hidden;
  z-index: -10;
  background-color: #fff;
  flex-direction: column;
  align-items: flex-end;
  min-width: 32rem;
  padding: 8rem 0 2rem 1rem;
  transition: all .3s;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateY(-35rem);
  box-shadow: 0 8px 16px #0003;
}

@media only screen and (max-width: 75em) {
  .navbar__nav-dropdown--content {
    padding-left: 4rem;
    padding-right: 4vw;
  }
}

@media only screen and (max-width: 56.25em) {
  .navbar__nav-dropdown--content {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .navbar__nav-dropdown--content {
    width: 100vw;
  }
}

.navbar__checkbox:checked ~ .navbar__nav-dropdown--content {
  visibility: visible;
  transform: translateY(0);
}

.navbar__button--open:hover .navbar__icon:before {
  top: -1rem;
}

.navbar__button--open:hover .navbar__icon:after {
  top: 1rem;
}

.navbar__checkbox:checked + .navbar__button--open .navbar__icon {
  background-color: #0000;
}

.navbar__checkbox:checked + .navbar__button--open .navbar__icon:before {
  top: 0;
  transform: rotate(135deg);
}

.navbar__checkbox:checked + .navbar__button--open .navbar__icon:after {
  top: 0;
  transform: rotate(-135deg);
}

.navbar__scrolled-dark {
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #ffffffb3;
  box-shadow: 0 4px 30px #0000001a;
}

.navbar__btn--scrolled-dark {
  color: #000 !important;
}

.navbar__btn--scrolled-dark:hover {
  border-bottom: 2px solid #000;
}

.section {
  transition: height 999999s;
  position: relative;
  overflow: clip;
}

.section--active {
  opacity: 1;
  animation-name: moveInBottomBig;
  animation-duration: 1s;
  animation-timing-function: ease;
}

.ablauf {
  height: 100dvh;
  margin-top: 7rem;
  padding: 5rem 5rem 2rem;
}

@media only screen and (max-width: 37.5em) {
  .ablauf {
    height: auto;
    padding: 5rem 4rem 0;
  }
}

@media only screen and (max-height: 37.5em) {
  .ablauf {
    height: auto;
    padding: 5rem 4rem 0;
  }
}

.ablauf__container {
  justify-content: center;
  display: grid;
}

.ablauf__container--item {
  text-align: center;
}

.ablauf__container--item:first-of-type {
  padding: 2rem 0 4rem;
}

@media only screen and (max-width: 37.5em) {
  .ablauf__container--item:first-of-type {
    padding: 2.5dvh 0 5dvh;
  }
}

@media only screen and (max-height: 53.25em) {
  .ablauf__container--item:first-of-type {
    padding: 1dvh 0 2.5dvh;
  }
}

.ablauf__container--item:not(:first-of-type) {
  padding: 4rem 0;
}

@media only screen and (max-width: 37.5em) {
  .ablauf__container--item:not(:first-of-type) {
    padding: 5dvh 0;
  }
}

@media only screen and (max-height: 53.25em) {
  .ablauf__container--item:not(:first-of-type) {
    padding: 2.5dvh 0;
  }
}

.ablauf__zeit {
  font-family: amandine, sans-serif;
  font-size: 3rem;
}

.ablauf__agendapunkt {
  font-size: 2.5rem;
  font-weight: 100;
}

.ablauf__trennlinie {
  border: .5px solid #000;
  justify-self: center;
  width: 6rem;
  height: 0;
  margin: 3px;
}

.hotel {
  height: 100dvh;
  margin-top: 7rem;
  padding: 5rem 5rem 2rem;
}

@media only screen and (max-width: 37.5em) {
  .hotel {
    height: auto;
    padding: 5rem 4rem 0;
  }
}

@media only screen and (max-height: 37.5em) {
  .hotel {
    height: auto;
    padding: 5rem 4rem 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .hotel__heading {
    margin-bottom: 2rem !important;
  }
}

.hotel__subheading-2 {
  text-align: center;
  width: 80vw;
  max-width: 110rem;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: futura-pt, sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
}

@media only screen and (max-width: 37.5em) {
  .hotel__subheading-2 {
    text-align: justify;
    width: 100%;
    margin: 0;
    font-size: 2.5rem;
    padding: 2rem 0 0 !important;
  }
}

.hotel__hotel-container {
  justify-content: center;
  padding: 5rem 0 10rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .hotel__hotel-container {
    padding: 1rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .hotel__hotel-container {
    padding: 0;
  }
}

.hotel__hotel-container--grid {
  grid-template-columns: 1fr 3px 1fr 3px 1fr 3px 1fr;
  align-content: center;
  justify-items: center;
  width: 80vw;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .hotel__hotel-container--grid {
    width: 100vw;
  }
}

@media only screen and (max-width: 56.25em) {
  .hotel__hotel-container--grid {
    grid-template-rows: 1fr 3px 1fr 3px 1fr 3px 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 37.5em) {
  .hotel__hotel-container--grid {
    grid-template-rows: none;
    grid-template-columns: 1fr;
  }
}

.hotel__data {
  padding: 5rem;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .hotel__data {
    padding: 3rem 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .hotel__data {
    text-align: center;
    padding: 5rem 0;
    display: block;
  }
}

.hotel__data--detail {
  text-align: center;
  font-family: amandine, sans-serif;
  font-size: 2.7rem;
}

@media only screen and (max-width: 37.5em) {
  .hotel__data--detail {
    margin: 0 !important;
  }
}

.kontakt {
  height: 100dvh;
  margin-top: 7rem;
  padding: 5rem 5rem 2rem;
}

@media only screen and (max-width: 37.5em) {
  .kontakt {
    height: auto;
  }
}

@media only screen and (max-height: 37.5em) {
  .kontakt {
    height: auto;
  }
}

.kontakt__text {
  text-align: center;
  width: 80vw;
  max-width: 110rem;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: futura-pt, sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
}

@media only screen and (max-width: 37.5em) {
  .kontakt__text {
    text-align: justify;
    width: 100%;
    margin: 0;
    font-size: 2.5rem;
    padding: 2rem 0 0 !important;
  }
}

@media only screen and (max-height: 53.25em) {
  .kontakt__text {
    width: 100%;
    padding: 2rem 0 0 !important;
  }
}

.kontakt__daten--container {
  padding: 3rem 0;
  font-family: futura-pt, sans-serif;
  font-size: 2.5rem;
}

@media only screen and (max-width: 37.5em) {
  .kontakt__daten--container {
    margin: 0 -2rem;
  }
}

@media only screen and (max-height: 53.25em) {
  .kontakt__daten--container {
    padding: 1rem 0;
  }
}

.kontakt__daten--grid {
  -moz-column-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5rem;
  display: grid;
}

@media only screen and (max-width: 25em) {
  .kontakt__daten--grid {
    grid-template-columns: 1fr;
  }
}

.kontakt__name {
  justify-self: end;
}

@media only screen and (max-width: 25em) {
  .kontakt__name {
    justify-self: center;
    margin-bottom: 1rem;
  }
}

.kontakt__nummer {
  text-align: center;
  cursor: pointer;
  color: #000;
  border-bottom: 2px solid #0000;
  justify-self: start;
  font-weight: 100;
  text-decoration: none;
  transition: all .2s;
}

@media only screen and (max-width: 25em) {
  .kontakt__nummer {
    justify-self: center;
    margin-bottom: 2.5rem;
  }
}

.kontakt__nummer:hover {
  color: #aaa;
  border-bottom: 2px solid #aaa;
}

.locations {
  height: 100dvh;
  margin-top: 7rem;
  padding: 5rem 5rem 2rem;
}

@media only screen and (max-width: 37.5em) {
  .locations {
    height: auto;
    padding: 5rem 4rem 0;
  }
}

@media only screen and (max-height: 37.5em) {
  .locations {
    height: auto;
    padding: 5rem 4rem 0;
  }
}

@media only screen and (min-width: 112.5em) {
  .locations {
    padding: 5rem calc(50vw + -900px + 5rem);
  }
}

.locations__location-container {
  justify-content: center;
  padding: 5rem 0 10rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .locations__location-container {
    padding: 0;
  }
}

.locations__location-container--grid {
  grid-template-columns: 1fr 10px 1fr 10px 1fr;
  align-content: center;
  justify-items: center;
  width: 95vw;
  max-width: 200rem;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .locations__location-container--grid {
    grid-template-columns: 1fr 2px 1fr 2px 1fr;
    width: 100vw;
  }
}

@media only screen and (max-width: 56.25em) {
  .locations__location-container--grid {
    grid-template-rows: 1fr 2px 1fr 2px 1fr;
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 37.5em) {
  .locations__location-container--grid {
    grid-template-rows: none;
    grid-template-columns: 1fr;
    padding: 0;
  }
}

.locations__subheading-1 {
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
  font-family: amandine, sans-serif;
  font-size: 2rem;
  font-style: oblique;
}

.locations__subheading-2 {
  text-align: center;
  width: 80vw;
  max-width: 110rem;
  margin: 0 auto;
  padding: 2rem 0;
  font-family: futura-pt, sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
}

@media only screen and (max-width: 37.5em) {
  .locations__subheading-2 {
    width: 100%;
    margin: 0;
  }
}

.locations__line {
  border: .5px solid #000;
  place-self: center;
  width: 0;
  height: 50%;
}

@media only screen and (max-width: 56.25em) {
  .locations__line {
    width: 15rem;
    height: 0;
  }
}

.locations__location {
  grid-template-rows: 1fr;
  padding: 5rem;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .locations__location {
    padding: 5rem 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .locations__location {
    text-align: center;
    padding: 7rem 0;
    display: block;
  }
}

.locations__location--name {
  text-align: center;
  font-family: amandine, sans-serif;
  font-size: 2.7rem;
}

.locations__location--text {
  text-align: center;
  padding: 2rem 0;
  font-family: futura-pt, sans-serif;
  font-size: 2.5em;
  font-style: oblique;
  font-weight: 100;
}

@media only screen and (max-width: 37.5em) {
  .locations__location--text {
    padding: 1.5dvh 0;
  }
}

.locations__location--adresse {
  text-align: center;
  cursor: pointer;
  color: #000;
  border-bottom: 2px solid #0000;
  justify-self: center;
  width: -moz-fit-content;
  width: fit-content;
  font-family: futura-pt, sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
  text-decoration: none;
  transition: all .2s;
}

.locations__location--adresse:hover {
  color: #aaa;
  border-bottom: 2px solid #aaa;
}

.our-story {
  height: 100dvh;
  margin-top: 7rem;
}

@media only screen and (max-width: 37.5em) {
  .our-story {
    height: auto;
  }
}

@media only screen and (max-height: 37.5em) {
  .our-story {
    height: auto;
  }
}

.our-story__heading {
  padding: 0 3rem;
}

.our-story__grid-container {
  grid-template-columns: 30vw 1fr;
  display: grid;
}

@media only screen and (max-width: 75em) {
  .our-story__grid-container {
    display: block;
  }
}

.our-story__fotocontainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 75em) {
  .our-story__fotocontainer {
    display: none;
  }
}

.our-story__foto {
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 30vw;
  height: 100dvh;
}

.our-story__subheading-1 {
  text-align: center;
  margin-left: 4rem;
  margin-right: 4rem;
  padding-bottom: 3rem;
  font-family: amandine, sans-serif;
  font-size: 2rem;
  font-style: oblique;
}

@media only screen and (max-width: 37.5em) {
  .our-story__subheading-1 {
    display: none;
  }
}

.our-story__text {
  text-align: justify;
  -moz-columns: 2;
  columns: 2;
  -moz-column-gap: 5rem;
  -webkit-hyphens: auto;
  hyphens: auto;
  column-gap: 5rem;
  max-width: 110rem;
  margin: 0 auto;
  padding: 2rem 7rem 2rem 5rem;
  font-family: futura-pt, sans-serif;
  font-size: 2.5rem;
  font-weight: 100;
}

@media only screen and (max-width: 37.5em) {
  .our-story__text {
    -moz-columns: 1;
    columns: 1;
    padding: 0 4rem;
    font-size: 2.5rem;
  }
}

.our-story__text--container {
  padding: 5rem 0 2rem;
}

.photo-quote__container {
  justify-content: center;
  align-items: center;
  height: 100dvh;
  min-height: 70rem;
  margin-top: 7rem;
  scroll-margin-top: calc(7rem - 2px);
  transition: height 999999s;
  display: flex;
}

.photo-quote__bg-image {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
  z-index: -100;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 37.5em) {
  .photo-quote__bg-image {
    width: 100%;
    height: 100dvh !important;
  }
}

.photo-quote__bg-image--bottom {
  -o-object-position: bottom !important;
  object-position: bottom !important;
}

.photo-quote__bg-image--container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.photo-quote__quote {
  text-align: center;
  color: #fff;
  z-index: 100;
  justify-content: center;
  align-items: center;
  width: 70rem;
  margin: 0 auto;
  padding: 10rem 0 0;
  font-family: amandine, sans-serif;
  font-size: 3rem;
  font-weight: 500;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.not-found-404 {
  height: calc(100dvh - 7rem);
  min-height: 70rem;
  margin-top: 7rem;
  padding: 0 0 0 7vw;
  scroll-margin-top: calc(7rem - 2px);
}

@media only screen and (min-width: 112.5em) {
  .not-found-404 {
    padding: 5rem calc(50vw + -900px + 5rem);
  }
}

.not-found-404__header {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
}

.not-found-404__header--message-1 {
  color: #bf9f58;
  font-family: liana, cursive;
  font-size: 22rem;
  line-height: 1;
}

@media only screen and (max-width: 37.5em) {
  .not-found-404__header--message-1 {
    font-size: 20rem;
  }
}

@media only screen and (max-width: 25em) {
  .not-found-404__header--message-1 {
    font-size: 18rem;
  }
}

.not-found-404__header--message-2 {
  width: 50vw;
  padding: 7dvh 0 1rem;
  font-size: 2.7rem;
  font-weight: 300;
}

@media only screen and (max-width: 37.5em) {
  .not-found-404__header--message-2 {
    width: 70vw;
  }
}

.not-found-404__header--message-main {
  width: 50vw;
  padding: 1rem 0 7dvh;
  font-size: 2rem;
  font-weight: 300;
}

@media only screen and (max-width: 37.5em) {
  .not-found-404__header--message-main {
    width: 70vw;
  }
}

.not-found-404__btn {
  opacity: 1;
  justify-content: center;
  width: 35rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .not-found-404__btn {
    width: 80%;
    font-size: 1.8rem !important;
  }
}

@media only screen and (max-width: 25em) {
  .not-found-404__btn {
    width: 100%;
    font-size: 1.8rem !important;
  }
}

#fp-nav {
  z-index: 100;
  opacity: 1;
  transform: translateY(-50%);
  position: fixed;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
}

@media only screen and (max-width: 37.5em) {
  #fp-nav {
    display: none !important;
  }
}

#fp-nav.fp-right {
  right: 17px;
}

#fp-nav.fp-left {
  left: 17px;
}

#fp-nav ul, .fp-slidesNav ul {
  margin: 0;
  padding: 0;
}

#fp-nav ul li, .fp-slidesNav ul li {
  width: 14px;
  height: 13px;
  margin: 7px;
  display: block;
  position: relative;
}

.fp-slidesNav ul li {
  display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

#fp-nav ul li a.active span, .fp-slidesNav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li:hover a.active span {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  margin: -6px 0 0 -6px;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
  z-index: 1;
  background: #000;
  border: 0;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
  width: 10px;
  height: 10px;
  margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
  color: #fff;
  white-space: nowrap;
  opacity: 0;
  cursor: pointer;
  width: 0;
  max-width: 220px;
  font-family: arial, helvetica, sans-serif;
  font-size: 14px;
  display: block;
  position: absolute;
  top: -2px;
  overflow: hidden;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active + .fp-tooltip {
  opacity: 1;
  width: auto;
  transition: opacity .2s ease-in;
}

#fp-nav ul li .fp-tooltip.fp-right {
  right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
  left: 20px;
}

.fp-sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.fp-noscroll .fp-overflow {
  overflow: hidden;
}

/*# sourceMappingURL=index.3cabd004.css.map */
